<template>
<div>
  <b-card no-body>
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Nhập tên nhóm, mô tả để tìm ..."
            />
          </div>
        </b-col>

        <b-col
          cols="12" md="6"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
          <label>Số bản ghi</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            style="min-width:80px"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            @click="New()" >
            Thêm nhóm quyền
          </b-button>
        </b-col>

      </b-row>

    </div>
    <b-table responsive="sm" :items="items" :fields="fields">
      <template #cell(name)="row">
        {{ row.value.first }} {{ row.value.last }}
      </template>

      <template #cell(actions)="row">
        <b-button variant="primary" v-b-tooltip.hover.top="'Phân quyền truy cập chức năng!'" size="sm" @click="Permission(row.item, row.index, $event.target)" class="mr-1">
          <feather-icon icon="UserCheckIcon"/>
        </b-button>
        <b-button variant="primary" size="sm" v-b-tooltip.hover.top="'Cập nhật nhóm quyền'" @click="Edit(row.item, row.index, $event.target)" class="mr-1">
          <feather-icon icon="EditIcon"/>
        </b-button>
        <b-button variant="danger" size="sm" v-b-tooltip.hover.top="'Xoá nhóm quyền'" @click="Delete(row.item, row.index, $event.target)">
          <feather-icon icon="DeleteIcon"/>
        </b-button>
      </template>

    </b-table>
  </b-card>
  <b-modal id="modal-edit" :title="modal_title" @ok="handleOk">
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col cols="6">
          <validation-provider #default="{ errors }" rules="required">
            <b-form-group label="Mã nhóm" >
              <b-form-input :state="errors.length > 0 ? false:null" v-model="currentItem.ID" placeholder="Mã nhóm"/>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Tên nhóm">
            <validation-provider #default="{ errors }" rules="required">
              <b-form-input :state="errors.length > 0 ? false:null" v-model="currentItem.ROLE_NAME" placeholder="Tên nhóm"/>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Mô tả">
            <b-form-input v-model="currentItem.DESCRIPTION" placeholder="Mô tả"/>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
  <b-modal id="modal-permission" size="lg" scrollable body-class="p-0" title="Phân quyền chức năng" @ok="handPermission">
    <b-table responsive="sm" :items="permisItems" :fields="perFields">
      <template #cell(name)="row">
        {{ row.value.first }} {{ row.value.last }}
      </template>
      <template #cell(C)="row">
        <b-form-checkbox v-model="row.item.C"/>
      </template>
      <template #cell(R)="row">
        <b-form-checkbox v-model="row.item.R"/>
      </template>
      <template #cell(U)="row">
        <b-form-checkbox v-model="row.item.U"/>
      </template>
      <template #cell(D)="row">
        <b-form-checkbox v-model="row.item.D"/>
      </template>
    </b-table>
  </b-modal>
</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BFormSelect,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BModal,BFormCheckbox,BFormGroup,VBTooltip } from 'bootstrap-vue'
import useJwt from "@/auth/jwt/useJwt";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from 'vue-ripple-directive'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';

export default {
  components: {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,VueGoodTable,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,vSelect,BFormSelect,
    FeatherIcon,BModal,BFormGroup,BFormCheckbox,ValidationProvider, ValidationObserver
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mounted(){
    this.fetchGroup();
  },
  watch: {
    perPage: function(n,o){
      this.fetchGroup()
    },
    searchQuery: function(n,o){
      this.fetchGroup()
    }
  },
  methods: {
    fetchPermission: function() {
      this.permisItems=[];
      useJwt.get(`masterData/groupPermission/${this.currentItem.ID}`,{})
        .then(response =>{
          this.permisItems = response.data.result;
          this.permisItems.forEach(function(i,v){
            i.C = i.C==1?true:false;
            i.R = i.R==1?true:false;
            i.U = i.U==1?true:false;
            i.D = i.D==1?true:false;
          })
        })
        .catch(error => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Lỗi lấy danh sách quyền theo nhóm '+this.currentItem.ROLE_NAME,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    fetchGroup: function() {
      useJwt.get('masterData/group', {
          params: {
            search: this.searchQuery,
            page: 1,
            pageIndex: this.perPage
          },
        })
        .then(response =>{
          this.items = response.data.result;
        })
        .catch(error => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Lỗi lấy danh sách nhóm',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    Permission(item, index, button){
      this.currentItem = item;
      this.fetchPermission();
      this.$bvModal.show('modal-permission')
    },
    ReportPermission(item, index, button){
      this.currentItem = item;
      this.fetchReportPermission();
      this.$bvModal.show('modal-report-permission')
    },
    Edit(item, index, button) {
      this.currentItem = item;
      this.modal_title= "Cập nhật nhóm quyền"
      this.$bvModal.show('modal-edit')
    },
    New(){
      this.currentItem={}
      this.modal_title= "Thêm mới nhóm quyền"
      this.$bvModal.show('modal-edit')
    },
    async Delete(item, index, button) {
      this.currentItem = item;
      var res = await this.$bvModal.msgBoxConfirm('Vui lòng xác nhận bạn muốn xoá nhóm: '+item.ROLE_NAME, {title: 'Please Confirm',size: 'sm',})
      if (res){
        useJwt.delete(`masterData/group/${this.currentItem.ID}`, {})
        .then(response =>{
          this.fetchGroup();
          this.$toast({component: ToastificationContent,props: {title: 'Xoá thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          console.log(error);
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi xoá nhóm người dùng',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }
    },
    handPermission(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.permisItems.forEach(function(i,v){
        i.C = i.C==null?false:i.C;
        i.R = i.R==null?false:i.R;
        i.U = i.U==null?false:i.U;
        i.D = i.D==null?false:i.D;
      })
      useJwt.put(`masterData/groupPermission/${this.currentItem.ID}`, {
          p: this.permisItems.filter(i => i.C || i.R || i.U || i.D)
        })
        .then(response =>{
          this.fetchPermission();
          this.$bvModal.hide('modal-permission')
          this.$toast({component: ToastificationContent,props: {title: 'Cập nhật thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          console.log(error);
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi cập nhật quyền cho nhóm',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
    },
    async handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      var validate = await this.$refs.simpleRules.validate().then(success => {return success;});
      if (!validate){
        this.$toast({component: ToastificationContent,props: {title: 'Vui lòng kiểm tra dữ liệu!',icon: 'AlertTriangleIcon',variant: 'warning'}})
        return;
      }
      if (this.modal_title=="Thêm mới nhóm quyền"){
        useJwt.post('masterData/group', {
          id: this.currentItem.ID,
          name: this.currentItem.ROLE_NAME,
          description: this.currentItem.DESCRIPTION
        })
        .then(response =>{
          this.fetchGroup();
          this.$bvModal.hide('modal-edit')
          this.$toast({component: ToastificationContent,props: {title: 'Thêm mới thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          console.log(error);
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi tạo mới nhóm người dùng',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }else{
        useJwt.put(`masterData/group`, {
          id: this.currentItem.ID,
          name: this.currentItem.ROLE_NAME,
          description: this.currentItem.DESCRIPTION
        })
        .then(response =>{
          this.fetchGroup();
          this.$bvModal.hide('modal-edit')
          this.$toast({component: ToastificationContent,props: {title: 'Cập nhật thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          console.log(error);
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi cập nhật nhóm người dùng',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }
    }
  },
  data() {
    return {
      items: [],
      permisItems: [],
      reportItems: [],
      perPageOptions: [10, 25, 50, 100],
      perPage:10,
      searchQuery:"",
      currentItem:{},
      modal_title: "Thêm mới nhóm quyền",
      fields: [
        {
          key: 'ID',
          sortable: true,
          label: 'Mã nhóm'
        },
        {
          key: 'ROLE_NAME',
          sortable: true,
          label: 'Tên nhóm'
        },
        {
          key: 'DESCRIPTION',
          label: 'Mô tả',
          sortable: false,
        },
        { key: 'actions', label: 'Actions' }
      ],
      reportFields: [
        {
          field: 'NAME',
          sortable: true,
          label: 'Chức năng',
          filterOptions: {enabled: true},
        },
        {
          field: 'CODE',
          sortable: true,
          label: 'Mã',
          filterOptions: {enabled: true},
        },
        {
          field: 'V',
          sortable: true,
          label: 'Quyền xem',
        },
      ],
      perFields: [
        {
          key: 'FUNCTION_NAME',
          sortable: true,
          label: 'Chức năng'
        },
        {
          key: 'C',
          sortable: true,
          label: 'Đọc'
        },
        {
          key: 'R',
          sortable: true,
          label: 'Xem'
        },
        {
          key: 'U',
          sortable: true,
          label: 'Cập nhật'
        },
        {
          key: 'D',
          sortable: true,
          label: 'Xoá'
        },
      ],
      required
    }
  },
}
</script>
<style>
.vgt-table td,.vgt-table th{
  padding: 0.35em 0.55em !important;
  vertical-align: middle !important;
  font-size: 1rem;
}
.vgt-table th{
  text-align: center;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>